.placeholder {
  height: 10rem;
  @media (min-width: 768px) {
    height: 23rem;
  }
}

.slidata_wrapper {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.image {
  object-fit: cover;
}
