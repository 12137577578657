.cardImage {
  background-color: transparent;
  border: none;
  cursor: pointer !important;
}

.image {
  border-radius: 0;
  object-fit: cover;
}

// Color circle identifier
.circlecolor {
  box-shadow: 0 0 2px rgb(133, 133, 133);
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
}
