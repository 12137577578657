@import "@/styles/_variables.scss";

.emptyheart {
  fill: white;
  stroke: black;
}

.likedheart {
  fill: red;
  stroke: red;
}

.likedheart:focus {
  outline: none !important;
  box-shadow: none !important;
}

.emptyheart:hover {
  fill: red;
  stroke: red;
}
