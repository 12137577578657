// FONT SIZES
.fs-7 {
  font-size: 0.8rem !important;
}

.fs-8 {
  font-size: 0.6875rem !important;
}

// FONT WEIGHT
.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

// COLORS
$form-focus-color: #93989c;
$off-white: #faf9f8;
$off-black: #222222;
$dark-gray: #293133;
$material-gray: #6e6e6e;
$dark-btn-hover: #2e2e2e;
$light-btn-hover: #e5e4e3;
$gray-border: #ced4da;
$gray-footer: #e9e9e9;
$red-links: #f0250e;
$option-hover: #deebff;

$orange: #ffa500;
$sky-blue: #add8e6;
$beige: #f5f5dc;
$white: #ffffff;
$blue: #092555;
$yellow: #ffff00;
$gray: #808080;
$black: #000000;
$pink: #fe86a4;
$red: #ff0000;
$green: #00bb2d;
$violet: #8f00ff;

// Banners
$banner-green: #527229;
$banner-orange: #c06c24;
$banner-blue: #6188c1;

// Scrollbar
$scrollbar: #a0a1a5;
$scrollbar-hover: #898a8f;
$scrollbar-active: #717379;

$scrollbar-dropdown-shoppingbag: #b8b9bc;
$scrollbar-dropdown-shoppingbag-hover: #a0a1a5;
$scrollbar-dropdown-shoppingbag-active: #898a8f;
