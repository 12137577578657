.imagenotfound {
  visibility: visible;
  height: 50% !important;
  left: 0px;
  position: absolute;
  top: 25% !important;
  width: 100%;
}

.cardImage {
  background-color: transparent;
  border: none;
  cursor: pointer !important;
}

.image {
  border-radius: 0;
  object-fit: cover;
}

//Color circle identifier
.circlecolor {
  box-shadow: 0 0 2px rgb(133, 133, 133);
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
}

.img_overlay {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 25%);
}
