.banner_wrapper {
  width: 100%;
}

.button {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.4rem 1rem 0.4rem 1rem;
}

.subtitle {
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
}

.buttons {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  white-space: nowrap;
}