@import "@/styles/_variables.scss";

.banner_wrapper {
  height: auto;
}

.card_overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.banner_title {
  color: $off-white;
  @media (max-width: 992px) {
    padding-bottom: 0 !important;
  }
}

.title {
  @media (max-width: 768px) {
    font-size: 24px !important;
  }
}

.banner_subtitle {
  color: $off-white;
  display: none !important;
  @media (min-width: 992px) {
    padding-bottom: 0;
    display: flex !important;
  }
}

.button {
  display: none !important;
  @media (min-width: 992px) {
    display: flex !important;
  }
}

.customButton:hover {
  background-image: linear-gradient(rgb(0 0 0/15%) 0 0);
}

.image {
  object-fit: fill;
  border-radius: 0 !important;
}

.text_wrapper {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  height: 50%;
  bottom: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
  @media (min-width: 992px) {
    padding-bottom: 2rem;
  }
}
